const LocationList =
{
  "pov": { heading: 274.11771349232777, pitch: 3.9530335529498473, zoom: 3.1509434344537794 },
  "locations": [
    {
      "id": 0,
      "panoOpts":
      {
        "name": "Oslo",
        "position": { "lat": 59.91221353794798, "lng": 10.75313777849905 },
      },
      "pov": { heading: 274.11771349232777, pitch: 3.9530335529498473, zoom: 3.1509434344537794 },
    },
    {
      "id": 1,
      "panoOpts":
      {
        "name": "San Francisco",
        "position": { "lat": 37.78314231648832, "lng": -122.4092641969097 },
      },
      "pov": { heading: 36.36603802810845, pitch: 9.927906058499204, zoom: 2.1509434344537794 },
    },
    {
      "id": 2,
      "panoOpts":
      {
        "name": "New York City",
        "position": { "lat": 40.71361183892604, "lng": -73.99863756960207 },
      },
      "pov": { heading: 348.0416776598446, pitch: -1.5074547641362699, zoom: 1.2257502306638088 },
    },
    {
      "id": 3,
      "panoOpts":
      {
        "name": "London",
        "position": { "lat": 51.51010964649783, "lng": -0.07287854371249837 },
      },
      "pov": { heading: 241.76652437091272, pitch: -0.8130669098653271, zoom: 3.1509434344537794 },
    },
  ]
}

export default LocationList