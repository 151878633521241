const InstructionPopup = ({ onClick }) => {
  return (
    <div className="bg-white rounded-xl flex flex-col justify-between items-center text-[#000000] min-w-[280px] xs:min-w-[400px] max-h-[400px] overflow-y-scroll instructions">
      <div className="flex w-full justify-between items-center pt-5 px-5">
        <p className="text-[18px] font-bold">Instructions</p>
        <i className="icon-close text-[12px] text-[#51A6E3] hover:cursor-pointer" onClick={onClick}></i>
      </div>
      <div className="py-5 pl-5 pr-9">
        <p className="mb-2 text-[#6A6A6A] font-medium">Click on the capture button to capture the traffic sign image.</p>
        <p className="mb-2 text-[#6A6A6A] font-medium">Move the capture box to the sign that you want to identify.</p>
        <p className="mb-2 text-[#6A6A6A] font-medium">Click on the classify button to identify the traffic sign.</p>
        <p className="mb-2 text-[#6A6A6A] font-medium">The details of the traffic sign will appear on the bottom left corner of your screen.</p>
      </div>
    </div>
  )
}

export default InstructionPopup