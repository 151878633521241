import { useEffect, useRef, useContext, useState } from 'react'
import { LocationContext } from '../context/location-context'
import { IconButton } from './common/button'

const DragResize = ({ frameInfo, onClick, loading, resize, visibility }) => {
  const location = useContext(LocationContext)
  const thisNode = useRef(null)
  const thisFrame = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [width, setWidth] = useState(50)

  useEffect(() => {
    console.log("DragResize - useEffect")
    initDragElement()
    initResizeElement()
    cropImage()
    if (frameInfo) setPostion(frameInfo)
  })

  console.log("DragResize - Starting")

  function setPostion(frameInfo) {
    console.log("frameInfo", frameInfo)
    console.log("thisNode.currrnt", thisNode.current)
    var header_height = 25

    var _imgElement = document.getElementById("image_detect")
    var rect_c = _imgElement.getBoundingClientRect()

    var shiftY = rect_c.top
    var shiftX = rect_c.left

    thisNode.current.style.width = frameInfo.xmax - frameInfo.xmin + 50 + "px"
    thisFrame.current.style.height =
      frameInfo.ymax - frameInfo.ymin + header_height + "px"
    thisNode.current.style.top = frameInfo.ymin + shiftY - header_height + "px"
    thisNode.current.style.left = frameInfo.xmin + shiftX - 25 + "px"
  }

  function initDragElement() {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0
    var popups = document.getElementsByClassName("popup")
    var elmnt = null
    var currentZIndex = 100 //TODO reset z index when a threshold is passed

    for (var i = 0; i < popups.length; i++) {
      var popup = popups[i]
      var header = getHeader(popup)

      popup.onmousedown = function () {
        this.style.zIndex = "" + ++currentZIndex
      }

      if (header) {
        header.parentPopup = popup
        header.onmousedown = dragMouseDown
      }
    }

    function dragMouseDown(e) {
      setIsDragging(true)
      elmnt = this.parentPopup
      elmnt.style.zIndex = "" + ++currentZIndex

      e = e || window.event
      // get the mouse cursor position at startup:
      pos3 = e.clientX
      pos4 = e.clientY
      document.onmouseup = closeDragElement
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag
    }

    function elementDrag(e) {
      setIsDragging(true)

      if (!elmnt) {
        return
      }

      e = e || window.event
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + "px"
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px"
    }

    function closeDragElement() {
      setIsDragging(false)
      /* stop moving when mouse button is released:*/
      document.onmouseup = null
      document.onmousemove = null
      cropImage()
    }

    function getHeader(element) {
      var headerItems = element.getElementsByClassName("popup-header")

      if (headerItems.length === 1) {
        return headerItems[0]
      }

      return null
    }
  }

  function initResizeElement() {
    var popups = document.getElementById("frame_container")

    var element = null
    var startX, startY, startWidth, startHeight, newHeight, newWidth

    var both =document.getElementById("resizer-both")

    // var p = popups

    // var both = document.createElement("div")
    // both.className = "resizer-both"
    // p.appendChild(both)
    both.addEventListener("mousedown", initDrag, false)
    both.parentPopup = popups

    function initDrag(e) {
      element = this.parentPopup

      startX = e.clientX
      startY = e.clientY
      startWidth = parseInt(
        document.defaultView.getComputedStyle(element).width,
        10
      )
      startHeight = parseInt(
        document.defaultView.getComputedStyle(element).height,
        10
      )
      document.documentElement.addEventListener("mousemove", doDrag, false)
      document.documentElement.addEventListener("mouseup", stopDrag, false)
    }

    function doDrag(e) {
      setIsDragging(true)
      newWidth = startHeight + e.clientY - startY - 80 + "px"
      newHeight = startHeight + e.clientY - startY + "px"

      if (newWidth !== element.style.width) {
        newHeight = newWidth
      }
      if (newHeight !== element.style.width) {
        newWidth = newHeight
      }

      element.style.width = startHeight + e.clientY - startY - 80 + "px"
      element.style.height = startHeight + e.clientY - startY + "px"
      setWidth(startHeight + e.clientY - startY - 80)
    }

    function stopDrag() {
      setIsDragging(false)
      document.documentElement.removeEventListener("mousemove", doDrag, false)
      document.documentElement.removeEventListener("mouseup", stopDrag, false)
      cropImage()
    }
  }
  const cropImage = () => {
    if (!location.croppedImage) return

    console.log("cropImage")
    var _canvas = document.getElementById("mapImage")
    var _imgElement = document.getElementById("mapImage")
    var _frame = document.getElementById("frameToCapture")

    var rect_c = _imgElement.getBoundingClientRect()
    var rect_f = _frame.getBoundingClientRect()

    var y1 = rect_f.top - rect_c.top
    var x1 = rect_f.left - rect_c.left

    var tempCanvas = document.createElement("canvas")
    tempCanvas.width = rect_f.width
    tempCanvas.height = rect_f.height
    console.log(
      "At Capture - canvas size",
      tempCanvas.width,
      tempCanvas.height
    )

    var tCtx = tempCanvas.getContext("2d")
    _canvas.crossOrigin = "anonymous"
    tCtx.drawImage(
      _canvas,
      x1,
      y1,
      rect_f.width,
      rect_f.height,
      0,
      0,
      rect_f.width,
      rect_f.height
    )
    var dataUrl = tempCanvas.toDataURL("image/jpeg", 1.0)

    location.setCroppedImage(dataUrl)
  }

  return (
    <div className="flex flex-row justify-center items-center w-[100vw] h-[100vh] absolute bottom-0">
      <div ref={thisNode} id="frame_container" className={`z-20 bg-transparent text-center ${!frameInfo && 'h-[160px]'} w-[80px] min-h-[160px] min-w-[80px] max-h-[380px] max-w-[300px] flex flex-col justify-start border-[10px] border-white rounded-2xl overflow-auto absolute popup ${!visibility && 'hidden'}`}>
        {!frameInfo && 
          <div className="popup-header h-[40px] cursor-pointer z-50 bg-white font-medium text-[#51A6E3] text-base rounded-t-md pb-2">Move</div>
        }
        <div ref={thisFrame} id="frameToCapture" className="frame-to-capture">
          {/* {location.croppedImage && !isDragging && (
            <img
              src={location.croppedImage}
              alt="Cropped Image"
            />
          )} */}
        </div>
        <div id="resizer-both" className="w-6 h-8 z-20 absolute right-0 bottom-12 cursor-n-resize resizer-both">
          {resize && 
            <i className="icon-handle text-[#51A6E3]"></i>
          }
        </div>
        <div className="border-t-[10px] border-white bg-white">
          {frameInfo ? 
            <div className="text-sm">
              <div className="font-semibold">{frameInfo.name}</div>
              <div className="text-[#6A6A6A]">{frameInfo.confidence.toString().substring(0, 5)}</div>
            </div> : <IconButton  style="w-full" title="Classify" icon="icon-classify" onClick={onClick} loading={loading} width={width} />
          }
        </div>
      </div>
    </div>
  )
}

export default DragResize