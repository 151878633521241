import { useState, useRef, useContext } from 'react'
import {Buffer} from 'buffer'

import { LocationContext } from '../context/location-context'
import ImageCapture from './map-image/image-capture'
import ImageDetect from './map-image/image-detect'
import Map from './map'
import MapActionsBox from './map-actions-box'
import Instruction from './instruction/instruction'
import InstructionPopup from './instruction/instruction-popup'
import Result from './result'
import { detect } from '../api/analytics'

const Home = () => {
  const location = useContext(LocationContext)
  const map_obj = useRef(null)
  const last_panoOptions = useRef(null)
  
  const [framesList, setFramesList] = useState([])
  const [isCapturing, setIsCapturing] = useState(false)
  const [isDetecting, setIsDetecting] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [loading, setLoading] = useState(false)

  const setMapObj = (mapObj) => {
    map_obj.current = mapObj
  }

  const setPanoOptions = (panoOpts) => {
    last_panoOptions.current = panoOpts
  }

  const getImageURLForView = () => {
    if (!map_obj.current)
      return

    var panorama = map_obj.current.getStreetView();
    var fov = 180 / Math.pow(2, panorama.getZoom())
    var imgURL = `${process.env.REACT_APP_GOOGLE_STATIC_IMAGES_URL}?size=1200x800&pano=${panorama.getPano()}&heading=${panorama.getPov().heading}&pitch=${panorama.getPov().pitch}&fov=${fov}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
    console.log("body_control getImageURLForView", imgURL)
    return imgURL
  }

  const getObjectsFromView = async () => {
    fetch(getImageURLForView())
      .then((response) => response.blob())
      .then((blob) => {
        var reader = new FileReader();
        reader.onload = async function (event) {
          return await sendToDetect(event.target.result)
        };

        reader.readAsDataURL(blob);

      })
  }

  const dataURItoBlob = (dataURI) => {

    var byteString = Buffer.from(dataURI.split(',')[1], 'base64');
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.readUInt8(i);
    }
    var blob = new Blob([ab], { type: mimeString });

    return blob;
  }

  const sendToDetect = async (dataURI) => {
    setLoading(true)
    var imageData = dataURItoBlob(dataURI)
    var force = true
    var data = new FormData()
    data.append('file', imageData, 'sign.jpeg')
    data.append('force', force)

    const res = await detect(data)
    if (res) {
      setLoading(false)
      const { status, data } = res
      if (status === 200) {
        if (data["status"] === "OK") {
          console.log("success", data.result)
          processResponse(data.result)
        } else {
          console.log("ERROR - updatePredictedImageOnError");
        }
      }
    } else {
      setLoading(false)
      console.log("ERROR - updatePredictedImageOnError");
    }
  }

  const processResponse = (frames) => {
    var jsonFrames = JSON.parse(frames)
    setFramesList(jsonFrames)
  }

  const handleCapture = () => {
    setIsCapturing(true)
  }

  const handleDetect = () => {
    setIsDetecting(true)
    getObjectsFromView()
    location.setPanoOptions(last_panoOptions.current)
  }

  const handleBackToMap = () => {
    setIsCapturing(false)
    setIsDetecting(false)
    window.location.reload()
  }

  const handlePopup = () => {
    showPopup ? setShowPopup(false) : setShowPopup(true)
  }

  const handleCloseResult = () => {
    location.setResult(null)
  }

  return (
    <div className="h-[100vh] w-full">
      {isCapturing ? (
        <ImageCapture imageURL={getImageURLForView()} />
      ) : isDetecting ? (
        <ImageDetect imageURL={getImageURLForView()} objectInfo={framesList} loading={loading}  />
      ) : (
        <Map
          setMapObj={setMapObj}
          setPanoOptions={setPanoOptions}
        ></Map>
      )}
      <div className="absolute top-20 left-5 sm:left-10 right-5 sm:right-unset z-10">
        <MapActionsBox
          onCapture={handleCapture}
          onDetect={handleDetect}
          onBackToMap={handleBackToMap}
          capture={isCapturing}
          detect={isDetecting}
        />
      </div>
      <div className="absolute w-40 bottom-8 sm:bottom-10 left-5 sm:left-10 z-10">
        {!isCapturing &&
          !isDetecting &&
          (showPopup ? (
            <InstructionPopup onClick={handlePopup} />
          ) : (
            <Instruction onClick={handlePopup} />
          ))}
      </div>
      <div className="absolute w-40 bottom-8 sm:bottom-10 left-5 sm:left-10 z-10">
        {
          (isDetecting ||
          isCapturing ) &&
          location.result && <Result result={location.result} onClick={handleCloseResult} />
        }
      </div>
    </div>
  )
}

export default Home
