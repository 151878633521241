import { useEffect, useContext, useState } from 'react'
import {Buffer} from 'buffer'

import { predict } from '../../api/analytics'
import DragResize from '../drag-resize'
import { LocationContext } from '../../context/location-context'

const ImageCapture = ({ imageURL }) => {
  const location = useContext(LocationContext)
  const [dataUrl, setDataUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isDisplay, setIsDisplay] = useState(false)

  useEffect(() => {
    setInterval(() => {
      setIsDisplay(true);
    }, 1000);
  }, []);

  function dragElement(elmnt, boundry) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0
    if (document.getElementById(elmnt.id)) {
      /* if present, the header is where you move the DIV from:*/
      document.getElementById(elmnt.id).onmousedown = dragMouseDown
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown
    }

    function dragMouseDown(e) {
      e = e || window.event
      e.preventDefault()
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      if (boundry.style.left > elmnt.offsetLeft - pos1) return;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
      cropImage();
    }
  }

  useEffect(() => {
    console.log("map_view imageURL", imageURL);
    dragElement(
      document.getElementById("frameToCapture"),
      document.getElementById("mapImage")
    )
    cropImage()
  })

  const cropImage = () => {
    var _canvas = document.getElementById("mapImage")
    var _imgElement = document.getElementById("mapImage")
    var _frame = document.getElementById("frameToCapture")

    var rect_c = _imgElement.getBoundingClientRect()
    var rect_f = _frame.getBoundingClientRect()

    var y1 = rect_f.top - rect_c.top
    var x1 = rect_f.left - rect_c.left

    var tempCanvas = document.createElement("canvas")
    tempCanvas.width = rect_f.width
    tempCanvas.height = rect_f.height

    var tCtx = tempCanvas.getContext("2d")
    _canvas.crossOrigin = "anonymous"
    // tCtx.fillStyle = "black"
    // tCtx.fillRect(0, 0, rect_f.width,rect_f.height)
    tCtx.drawImage(
      _canvas,
      x1,
      y1,
      rect_f.width,
      rect_f.height,
      0,
      0,
      rect_f.width,
      rect_f.height
    )
    var dataUrl = tempCanvas.toDataURL("image/jpeg", 1.0)

    location.setCroppedImage(dataUrl)
  }

  const onImgLoad = ({target:img}) => {
    let tempCanvas = document.createElement("canvas")
    tempCanvas.width = img.width
    tempCanvas.height = img.height

    let tCtx = tempCanvas.getContext("2d")
    img.crossOrigin = "anonymous"
    tCtx.drawImage(img, 0, 0)
    const _dataUrl = tempCanvas.toDataURL("image/jpeg", 1.0)
    setDataUrl(_dataUrl)
  }

  const dataURItoBlob = (dataURI) => {
    var byteString = Buffer.from(dataURI.split(",")[1], "base64")
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.readUInt8(i)
    }
    var blob = new Blob([ab], { type: mimeString })

    return blob
  }

  const onClassifyClicked = async () => {
    var imageData = dataURItoBlob(dataUrl)
    var force = true
    var data = new FormData()
    data.append("file", imageData, "sign.jpeg")
    data.append("force", force)
    setLoading(true)
    const res = await predict(data)
    if (res) {
      setLoading(false)
      const { status, data } = res
      if (status === 200) {
        const resultData = {
          lable: data.label,
          signName: data.name,
          confidence: data.probability,
        }
        if (data.is_traffic_sign) {
          location.setResult(resultData)
        } else {
          if (force) {
            location.setResult(resultData)
          } else {
            location.setResult(resultData)
            console.log("ERROR !force - updatePredictedImageOnError")
          }
        }
        location.setResult(resultData)
      }
    } else {
      setLoading(false)
      console.log("ERROR - updatePredictedImageOnError")
    }
  }

  return (
    <div id="imageDiv" className="image-view flex items-center justify-center h-[100vh] overflow-hidden">
      {location.croppedImage && (
        <img
          src={location.croppedImage}
          alt="Cropped Image"
          className="cropped-image hidden"
          id="croppedImage"
          onLoad={onImgLoad}
        />
      )}
      <img src={imageURL} alt="Captured Image" id="mapImage" className="overlay" />
      <DragResize onClick={onClassifyClicked} loading={loading} resize={true} visibility={isDisplay} />
    </div>
  )
}

export default ImageCapture
