import { useContext } from 'react'

import { LocationContext } from '../context/location-context'
import LocationList from '../data/locations'
import Button from './common/button'
import Select from './common/select'

const MapActionsBox = ({ onCapture, onDetect, onBackToMap, capture, detect }) => {
  const location = useContext(LocationContext)

  const handleChange = ({ target }) => {
    const { value } = target
    var index = parseInt(value)

    var panoramaOptions = {
      position: LocationList.locations[index].panoOpts.position,
      pov: LocationList.locations[index].pov,
    }
  
    location.setPanoOptions(panoramaOptions)
  }

  return (
    <div className="bg-white w-full sm:w-fit sm:h-14 rounded-xl flex justify-between  sm:items-center py-2 sm:py-8 px-2 flex-col sm:flex-row">
      <div className="sm:w-1/2 sm:mr-1 mb-2 sm:mb-0">
        <Select onChange={handleChange} options={LocationList.locations} testId="select-location" />
      </div>
      <div className="flex flex-row justify-between sm:justify-end">
        {
          (capture || detect) ?
            <Button title="Back to map" icon="icon-back" style="ml-1 sm:ml-2" onClick={onBackToMap} /> :
          <>
            <Button title="Capture" icon="icon-capture" style="mr-1 sm:ml-2 sm:mr-0 w-1/2" onClick={onCapture} />
            <Button title="Detect" icon="icon-detect" style="ml-1 sm:ml-2 w-1/2" onClick={onDetect} />
          </> 
        }
      </div>
    </div>
  )
}

export default MapActionsBox
