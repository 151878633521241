import PropTypes from 'prop-types'
import Loading from '../../assets/images/loading.gif'

const Button = ({ title, icon, style, onClick, loading }) => {
  return (
    <button type="button"
    className={`text-white bg-[#51A6E3] hover:bg-[#2ba5fc] focus:ring-1 focus:ring-[#97cbf0] font-medium rounded-xl text-sm h-12 px-5 py-2.5 focus:outline-none flex justify-center items-center ${style}`} onClick={onClick}>
      <div className="flex">
        {loading && <img src={Loading} width="20px" height="20px" className="mr-1" />}
        {icon && !loading && <i className={`${icon} text-[16px] mr-1`}></i>}
        <div>{title}</div></div>
    </button>
  )
}

export default Button

Button.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  style: PropTypes.string,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  title: 'Submit',
}

const IconButton = ({ title, icon, style, onClick, loading, width }) => {
  return (
    <button type="button"
    className={`text-white bg-[#51A6E3] hover:bg-[#2ba5fc] focus:ring-1 focus:ring-[#97cbf0] font-medium rounded-xl text-sm h-12 px-5 py-2.5 focus:outline-none flex justify-center items-center ${style}`} onClick={onClick}>
      <div className="flex">
        {loading && <img src={Loading} width="20px" height="20px" className="mr-1" />}
        {icon && !loading && <i className={`${icon} ${width > 100 ? 'text-[16px]' : 'text-[25px]' }`}></i>}
        <div>{width > 100 ? title : ''}</div></div>
    </button>
  )
}

export { IconButton }