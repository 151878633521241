import axiosController from './api-controller'
import axios from 'axios'

const predict = async (data) => {
  const response = await axiosController.post('/api/predict', data)
  return response
}

const detect = async (data) => {
  const response = await axios.post(process.env.REACT_APP_SERVER_DETECT_URL, data)
  return response
}

export {
  predict,
  detect,
}
