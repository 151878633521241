import DragResize from '../drag-resize'
import Loading from '../../assets/images/loading.gif'

const ImageDetect = ({ imageURL, objectInfo, loading }) => {
  const generateObjectFrames = (objectInfo) => {
    var frameComponents = [];
    objectInfo.map((frame, index) => {
      frameComponents.push(
        <DragResize
          key={index}
          croppedImage={null}
          frameInfo={frame}
          visibility={true}
        ></DragResize>
      )
    })
    return frameComponents
  }

  return (
    <div id="frame_detect" className="image-view flex items-center justify-center h-[100vh] overflow-hidden">
      {loading && 
        <img src={Loading} width="50px" height="50px" className="absolute left-[50vw - 25px] top-[50vh - 50px]" />
      }
      <img id="image_detect" src={imageURL} alt="Detect Image" className="overlay" />
      {generateObjectFrames(objectInfo)}
    </div>
  )
}

export default ImageDetect
