const Result = ({ result, onClick }) => {
  return (
    <div className="bg-white rounded-xl flex items-center text-[#000000] min-w-[280px] xs:min-w-[400px] max-h-[400px]">
      <div className="flex w-1/2 justify-between items-center px-5">
        <img src={`${process.env.REACT_APP_SERVER_IMAGES_URL}/${result.lable}.png`} className="mx-auto w-[90%]" />
      </div>
      <div className="py-5 pr-9 text-[14px] w-1/2">
        <div className="flex justify-between">
          <div>
            <p className="text-[#6A6A6A] font-medium">Class</p>
            <p className="mb-3 text-[#000000] font-medium">{result ? result.lable : ''}</p>
          </div>
          <i className="icon-close text-[12px] text-[#51A6E3] hover:cursor-pointer" onClick={onClick}></i>
        </div>
        <p className="text-[#6A6A6A] font-medium">Sign</p>
        <p className="mb-3 text-[#000000] font-medium">{result ? result.signName : ''}</p>
        <p className="text-[#6A6A6A] font-medium">Confidence</p>
        <p className="mb-3 text-[#000000] font-medium">{`${
        result ? (result.confidence * 100).toFixed(2) + '%' : ''
      }`}</p>
      </div>
    </div>
  )
}

export default Result
