import { useContext, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'

import { LocationContext } from '../context/location-context'

const Map = ({ setMapObj, setPanoOptions }) => {
  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)

  console.log("Map View Rendering")
  const location = useContext(LocationContext)

  useEffect(() => {
    if (!maps) {
      console.log("Map not loaded. Avoid setting panorama ")
      return
    }
    setPanorama(map, maps)
    return () => {
      // Clean up the subscription
      console.log("map_view leaving the screen")
    }
  })

  const setPanorama = (_map, _maps) => {
    var panoramaOptions = {
      position: location.panoOptions.position,
      pov: location.panoOptions.pov,
    }

    console.log("MapView::setPanorama - panoramaOptions", panoramaOptions)

    var panorama = new _maps.StreetViewPanorama(
      document.getElementById("mapDiv"),
      panoramaOptions
    )
    panorama.addListener("pano_changed", () => {
      onPanoPositionChang()
    })
    panorama.addListener("position_changed", () => {
      onPanoPositionChang()
    })
    panorama.addListener("pov_changed", () => {
      onPanoPositionChang()
    })

    _map.setStreetView(panorama)
    savePanoOptions()
  }

  const onPanoPositionChang = () => {
    savePanoOptions()
  }

  const savePanoOptions = () => {
    if(map) {
      var panorama = map.getStreetView()
  
      var panoramaOptions = {
        position: {
          lat: panorama.getPosition().lat(),
          lng: panorama.getPosition().lng(),
        },
        pov: panorama.getPov(),
      }  
      setPanoOptions(panoramaOptions)
    }
  }

  const defaultOptions = {
    mapTypeControlOptions: {
      mapTypeIds: ["roadmap", "satellite", "custom-map"],
    },
    fullscreenControl: false,
    streetViewControl: true,
  }

  const handleApiLoaded = (map, maps) => {
    setMap(map)
    setMaps(maps)

    setPanorama(map, maps)
    setMapObj(map) //Set map object in parent for image capturing
  }

  return (
    <div id="mapDiv" className="map-view">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        defaultZoom={12}
        defaultOptions={defaultOptions}
        yesIWantToUseGoogleMapApiInternals
        center={location.panoOptions.position}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </div>
  )
}

export default Map
