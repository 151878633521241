import { createContext, useState } from 'react'

export const LocationContext = createContext()
// This context provider is passed to any component requiring the context
export const LocationProvider = ({ children }) => {
  const initPanoOptions = {
    position: { lat: 59.91221353794798, lng: 10.75313777849905 },
    pov: {
      heading: 274.11771349232777,
      pitch: 3.9530335529498473,
      zoom: 3.1509434344537794,
    },
  }
  const [panoOptions, setPanoOptions] = useState(initPanoOptions)
  const [croppedImage, setCroppedImage] = useState(null)
  const [result, setResult] = useState(null)

  console.log("LocationProvider panoOptions", panoOptions)
  return (
    <LocationContext.Provider
      value={{
        panoOptions,
        setPanoOptions,
        croppedImage,
        setCroppedImage,
        result,
        setResult,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}
