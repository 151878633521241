import PropTypes from 'prop-types'

const Select = ({options, onChange, testId}) => {
  return (
    <select
    data-testid={testId}
    onChange={onChange}
    className="bg-[#F2F2F2] text-gray-900 text-sm rounded-lg focus:ring-blue-500 block p-2.5 h-12 w-full sm:w-[280px] border-r-[16px] border-r-transparent"
  >
    {options.map((option) => (
      <option key={option.id} value={option.id}>{option.panoOpts.name}</option>
    ))}
  </select>
  )
}

export default Select

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
}

Select.defaultProps = {
  options: [],
  testId: '',
}
