import { LocationProvider } from './context/location-context'
import Home from './components/home'
import './App.css'

const App = () => {
  return (
    <div className="flex h-[100vh] w-full">
      <LocationProvider>
        <Home viewType="map" />
      </LocationProvider>
    </div>
  )
}

export default App
